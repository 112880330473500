import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import style from "./VideoForm.module.css"; // Import the CSS file
import { IMAGES } from "../../assets/images/images";
import { useParams } from "react-router-dom";
import {
  MARK_VIDEO_AS_WATCHED,
  VIDEO_FORM_SUBMIT,
  SAVE_VIDEO_PROGRESS,
  GET_VIDEO_PROGRESS,
  GET_VIDEO_DETAILS,
  UPLOAD_VIDEO_IMAGES,
} from "../../services/URL";
import InputFields from "../Common/InputField/InputFields";

const VideoForm = () => {
  const [User, setUser] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [videoWatched, setVideoWatched] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [hasCompleted, setHasCompleted] = useState(false);
  const [initialTime, setInitialTime] = useState(0);
  const [isThankyouMsg, setIsThankyouMsg] = useState(false);
  const videoRef = useRef(null);
  const cameraRef = useRef(null);
  const canvasRef = useRef(null);
  const [cameraAccessGranted, setCameraAccessGranted] = useState(false);
  const [cameraAccessDenied, setCameraAccessDenied] = useState(false);
  const [currentPart, setCurrentPart] = useState(1);
  const [segmentDuration, setSegmentDuration] = useState();
  const [videoDetails, setVideoDetails] = useState();
  const { companyId, siteId } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const [photoCount, setPhotoCount] = useState(() => {
    const savedPhotoCount = localStorage.getItem("photoCount");
    return savedPhotoCount ? parseInt(savedPhotoCount, 10) : 0;
  });

  let initialPartCaptured = { part1: false, part2: false, part3: false };
  const savedPartCaptured = localStorage.getItem("partCaptured");

  if (savedPartCaptured) {
    try {
      initialPartCaptured = JSON.parse(savedPartCaptured);
    } catch (error) {
      initialPartCaptured = { part1: false, part2: false, part3: false };
    }
  }

  const partCaptured = useRef(initialPartCaptured);

  useEffect(() => {
    localStorage.setItem("photoCount", photoCount);
    localStorage.setItem("partCaptured", JSON.stringify(partCaptured.current));
  }, [photoCount, partCaptured.current]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const preventSpeedChange = () => {
        videoElement.playbackRate = 1;
      };

      videoElement.addEventListener("ratechange", preventSpeedChange);

      const preventContextMenu = (e) => e.preventDefault();
      videoElement.addEventListener("contextmenu", preventContextMenu);

      return () => {
        if (videoElement) {
          videoElement.removeEventListener("ratechange", preventSpeedChange);
          videoElement.removeEventListener("contextmenu", preventContextMenu);
        }
      };
    }
  }, [videoRef]);

  const captureUserPhoto = async () => {
    if (photoCount >= 3) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
      });
      cameraRef.current.srcObject = stream;
      const video = cameraRef.current;
      setPhotoCount((prev) => prev + 1);

      video.onloadedmetadata = () => {
        video.play();
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const imageData = canvas.toDataURL("image/png");
        uploadCapturedPhoto(imageData.toString());

        stream.getTracks().forEach((track) => track.stop());
      };
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  const handleVideoTimeUpdate = useCallback(() => {
    const video = videoRef.current;

    if (!segmentDuration || !video || videoWatched) return;

    if (video && userEmail && !videoWatched) {
      const { currentTime, duration } = video;
      if (currentTime <= segmentDuration) {
        setCurrentPart(1);
        if (!partCaptured.current.part1 && photoCount < 3) {
          captureUserPhoto();
          partCaptured.current.part1 = true;
        }
      } else if (currentTime <= segmentDuration * 2) {
        setCurrentPart(2);
        if (!partCaptured.current.part2 && photoCount < 3) {
          captureUserPhoto();
          partCaptured.current.part2 = true;
        }
      } else if (currentTime <= segmentDuration * 3) {
        setCurrentPart(3);
        if (!partCaptured.current.part3 && photoCount < 3) {
          captureUserPhoto();
          partCaptured.current.part3 = true;
        }
      }

      // Handle video completion
      if (currentTime === duration) {
        handleVideoCompletion();
        saveVideoProgress(currentTime);
      }
    }
  }, [photoCount, userEmail, videoWatched, segmentDuration]);

  const getFormDetail = async () => {
    try {
      const response = await axios.post(GET_VIDEO_DETAILS, {
        companyId,
        siteId,
      });
      const details = response.data?.videoDetails || {
        jobTitle: "",
        videoLink: "",
        notes: "",
        jobVisibility: false,
      };
      setVideoDetails(details);
    } catch (error) {
      console.error("Error fetching video details:", error);
    }
  };
  useEffect(() => {
    getFormDetail();
  }, [companyId, siteId]);
  useEffect(() => {
    if (userEmail && userEmail !== "") {
      axios
        .post(GET_VIDEO_PROGRESS, { email: userEmail })
        .then((response) => {
          if (response.data.watchedVideo) {
            setVideoWatched(true);
          }

          setInitialTime(response.data?.lastWatchedTime || 0);
        })
        .catch((error) => {
          console.error("Error fetching video progress:", error);
        });
    }
  }, [userEmail]);

  useEffect(() => {
    const UserEmail = localStorage.getItem("userEmail");
    requestCameraAccess();
    if (UserEmail) {
      setUserEmail(UserEmail);
      setIsFormSubmitted(true);
    }
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.currentTime = initialTime;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [initialTime, userEmail]);

  const requestCameraAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        setCameraAccessGranted(true);
        setCameraAccessDenied(false);
        stream.getTracks().forEach((track) => track.stop());
      }
    } catch (error) {
      console.error("Camera access denied:", error);
      setCameraAccessGranted(false);
      setCameraAccessDenied(true);
    }
  };
  const uploadCapturedPhoto = async (image) => {
    try {
      const formData = new FormData();
      formData.append("email", userEmail);
      formData.append("image", image);
      const response = await axios.post(UPLOAD_VIDEO_IMAGES, formData);
      console.log("Photo uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading photo:", error);
    }
  };

  const onSubmit = async (data) => {
    try {
      const body = {
        ...data,
        companyId: companyId,
        siteId: siteId,
      };
      const response = await axios.post(VIDEO_FORM_SUBMIT, body);
      if (response.data.data) {
        setUser(response.data.data);
        setHasCompleted(response.data.data.watchedVideo);
        setUserEmail(response.data.data.email);
      }
      setIsFormSubmitted(true);
      localStorage.setItem("userEmail", response.data.data.email);

      reset();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleVideoCompletion = async () => {
    try {
      const response = await axios.post(MARK_VIDEO_AS_WATCHED, {
        email: userEmail,
      });
      if (response.data.data) {
        setVideoWatched(true);
        setHasCompleted(true);
      }
    } catch (error) {
      console.error("Error updating video status:", error);
    }
  };
  const handleLoadedMetadata = () => {
    if (videoRef.current && initialTime > 0) {
      videoRef.current.currentTime = initialTime;
    }
    const videoElement = videoRef.current;
    const totalDuration = videoElement.duration;
    if (totalDuration) {
      const segmentTime = totalDuration / 3;
      setSegmentDuration(segmentTime);
    }
  };

  const saveVideoProgress = async (currentTime) => {
    try {
      await axios.post(SAVE_VIDEO_PROGRESS, {
        email: userEmail,
        currentTime: currentTime,
      });
    } catch (error) {
      console.error("Error saving video progress:", error);
    }
  };
  const handleLogOut = () => {
    setIsFormSubmitted(false);
    setIsThankyouMsg(false);
    setUserEmail("");
    setUser("");
    setVideoWatched(false);
    setErrorMessage("");
    setSegmentDuration(0);
    setPhotoCount(0);
    partCaptured.current = {
      part1: false,
      part2: false,
      part3: false,
    };
    reset();
    localStorage.removeItem("userEmail");
    localStorage.removeItem("photoCount");
    localStorage.removeItem("partCaptured");
  };

  const handleResubmitError = () => {
    setErrorMessage("Please watch the full video before proceeding.");
  };

  const handleBeforeUnload = () => {
    const video = videoRef.current;
    if (video && userEmail && !videoWatched) {
      saveVideoProgress(video.currentTime);
    }
  };

  const thankyouMsg = () => {
    return (
      <div className={style.completionScreen}>
        <div className={style.logoContainer}>
          <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
        </div>
        <p className={style.completionMessage}>
          You have Completed Watching Your Jobsite Orientation Video
        </p>
        <button onClick={handleLogOut} className={`${style.proceedButton}`}>
          Log Out
        </button>
      </div>
    );
  };
  const supportMessage = () => {
    return (
      <div className={style.completionScreen}>
        <div className={style.logoContainer}>
          <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
        </div>
        <p className={style.completionMessage}>
          You have not Jobsite Orientation Access please connect to support
        </p>
      </div>
    );
  };

  return (
    <div className={style.videoFormContainer}>
      {!videoDetails || !videoDetails.jobVisibility ? (
        supportMessage()
      ) : !isFormSubmitted ? (
        <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
          <h2 className={style.jobTitle}>{videoDetails.jobTitle}</h2>
          <div className={style.logoContainer}>
            <img className="mb-1" src={IMAGES.FormLogo} alt="logo" />
          </div>
          <h2 className={style.notes}>{videoDetails.notes}</h2>

          <div className={style.formGroup}>
            <input
              type="text"
              placeholder="Name"
              autoComplete="off"
              {...register("name", { required: "Name is required" })}
              className={style.input}
            />
            {errors.name && (
              <p className={style.errorMessage}>{errors.name.message}</p>
            )}
          </div>

          <div className={style.formGroup}>
            <input
              type="email"
              placeholder="Email"
              autoComplete="off"
              {...register("email", {
                required: "Email is required",

                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address",
                },
              })}
              className={style.input}
            />
            {errors.email && (
              <p className={style.errorMessage}>{errors.email.message}</p>
            )}
          </div>
          <div className={style.formGroup}>
            
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{
                required: "Phone is required",
                pattern: {
                  value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                  message: "Invalid phone number (+x xxx xxx xxxx).",
                },
              }}
              render={({ field }) => (
                <>
                  <InputFields
                    type="phone"
                    placeholder="+1 625 999 3488"
                    value={field.value}
                    index="phone"
                    maxLength={65}
                    handleChange={(e) => field.onChange(e)}
                    required
                  />
                  {errors.phone && (
                    <div className={`${style.errorMessage}`}>
                      {errors.phone.message}
                    </div>
                  )}
                </>
              )}
            />
          </div>

          {/* <div className={style.formGroup}>
            <input
              type="tel"
              placeholder="Phone"
              autoComplete="off"
              {...register("phone", {
                required: "Phone is required",
                pattern: {
                  value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                  message: "Invalid phone number",
                },
              })}
              className={style.input}
            />
            {errors.phone && (
              <p className={style.errorMessage}>{errors.phone.message}</p>
            )}
          </div> */}

          <button type="submit" className={style.submitButton}>
            Submit
          </button>
        </form>
      ) : isThankyouMsg ? (
        thankyouMsg()
      ) : cameraAccessGranted ? (
        <div className={style.videoContainer}>
          <div className={style.logoContainer}>
            <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
          </div>
          <video
            ref={videoRef}
            width="600"
            controls
            onLoadedMetadata={handleLoadedMetadata}
            onTimeUpdate={handleVideoTimeUpdate}
            className={`${videoWatched ? style.watchedVideo : style.video}`}
          >
            <source src={videoDetails.videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video ref={cameraRef} style={{ display: "none" }}></video>
          <canvas
            ref={canvasRef}
            width="640"
            height="480"
            style={{ display: "none" }}
          ></canvas>

          {!videoWatched && (
            <p className={style.errorMessage}>{errorMessage}</p>
          )}
          <button
            disabled={!videoWatched}
            onClick={
              videoWatched ? () => setIsThankyouMsg(true) : handleResubmitError
            }
            className={`${style.proceedButton} ${
              videoWatched ? "" : style.disabled
            }`}
          >
            Proceed
          </button>
        </div>
      ) : cameraAccessDenied ? (
        <div className={style.accessDeniedContainer}>
          <p className={style.accessDeniedMessage}>
            Please allow camera access to watch the video.
          </p>
        </div>
      ) : (
        <div className={style.loadingMessage}>
          Checking for camera permissions...
        </div>
      )}
    </div>
  );
};

export default VideoForm;
